var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"product-list-item"},[_c('v-col',{staticClass:"pr-md-7",attrs:{"cols":"12","md":"auto"}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.image,"width":"170","height":"250"},on:{"error":function($event){_vm.imageError = true}}})],1),_c('v-col',[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
        name: 'app.products.view',
        params: { id: _vm.product.id },
      }}},[_c('h2',[_vm._v(_vm._s(_vm.product.title))])]),_c('small',{staticStyle:{"opacity":"0.8"}},[_c('span',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.product.content_type ? _vm.product.content_type.name : "")+" ")]),_c('v-icon',{staticClass:"mr-1 pb-1",attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_c('span',[_vm._v(_vm._s(_vm.$formatDuration(_vm.product.runtime)))])],1),_c('div',_vm._l((_vm.product.genres),function(genre){return _c('v-chip',{key:genre.id,staticClass:"chip-transparent mr-3 mt-2",on:{"click":function($event){return _vm.$router.push({
            name: 'app.products.category',
            params: { id: genre.id },
          })}}},[_vm._v(" "+_vm._s(genre.name)+" ")])}),1),_c('h3',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("labels.synopsis")))]),_c('p',{staticClass:"synopsis mb-2"},[_vm._v(" "+_vm._s(_vm.product.synopsis)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},_vm._l((_vm.product.available_formats),function(format){return _c('v-chip',{key:format.id,staticClass:"mr-3 mt-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(format.name)+" ")])}),1),_c('v-col',{staticClass:"text-right"},[(!_vm.savedProduct)?[_c('v-btn',{attrs:{"color":"success","text":"","to":{
              name: 'app.products.edit',
              params: { id: _vm.product.id },
            }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.edit"))+" ")]),_c('confirm-dialog',{attrs:{"button-text":_vm.$t('buttons.delete'),"button-attrs":{
              color: 'error',
              text: true,
              loading:
                _vm.$store.getters['products/loading'].delete == _vm.product.id,
            }},on:{"confirm":_vm.deleteProduct}})]:[_c('BuyBundleButton',{attrs:{"product":_vm.product,"bundle":this.product.rights_bundles[0]}}),_c('v-btn',{staticClass:"ml-5",staticStyle:{"font-size":"15px","letter-spacing":"0px"},attrs:{"color":"error","text":"","loading":_vm.$store.getters['products/loading'].removeSavedProduct ==
              _vm.product.id},on:{"click":_vm.removeSavedProduct}},[_vm._v(" "+_vm._s(_vm.$t("buttons.removeFromSaved"))+" ")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }